;(function() {

	/**
	 * ImageLoader:
	 *
	 * Basically a replacement for lazysizes, when 'inview' is not
	 * the right kind of delay. This takes a queue of images and loads
	 * them in a queue, rather than all at once.
	 *
	 * Takes a array of selectors, with optional config for
	 * batch sizing, parallel loading or series laoding

	 	var queueConfig = [
	  		'.slideNotLazy', // will just load all .slideNotLazy at once. Same as writing ['.slideNotLazy', 'parallel']
			['.delayLoadThumb', 4], // will load delayLoadThumb in groups of 4
			['.slideLazy', 'serial'] // will load each slideLazy, one after the other
		];

	 * Selectors could be actual jquery elements or dom elements, as $(selector) will always
	 * happen, eg $( $(selector) ) === $(selector)
	 *
	 * All elements passed are expected to be <img> tags with data-src tags
	 * or any element with data-bg tags. In the former case, the data-src becomes
	 * src (like lazysizes) or style="background-image:url( data-bg )".
	 *
	 *
	 * @param  [array] queue // Array of selectors
	 */


	/**
	 * @WARN - seems to be a bug with queueing and promises resolving,
	 * could be just the network, could be delayed jquery events,
	 * either way we can pass in 'true' to constructor as second argument,
	 * so that we can ignore the load events, and just resolve the promise
	 * when an image has been added to load queue.
	 *
	 * Default is true!
	 */

	DelayedImageLoader = function( queueConfig, skipLoadEvent ) {

		var imageQueue = [];
		queueConfig.forEach( function( val ) {

			var _mode = 'parallel';

			if (typeof val === 'object') {
				var $el = $(val[0]);

				if (typeof val[1] === "number" ) {
					var batch = val[1];
					_mode = 'batch';
				} else if (val[1] === 'serial') {
					_mode = 'serial';
				}

			} else {
				var $el = $(val);
				var batch = 1;
			}

			if (_mode === 'batch') {

				var temp_array = [];
				// put in temp array...
				$el.each(function() {
					temp_array.push( this );
				});

				while (temp_array.length > 0) {
					var t = temp_array.splice(0,batch);
					imageQueue.push( t );
				}
			} else if (_mode === 'serial') {
				$el.each(function() {
					imageQueue.push( this );
				});
			} else {
				// parallel
				imageQueue.push( $el );
			}
		});

		// default is true
		if (typeof skipLoadEvent === "undefined") {
			skipLoadEvent = true;
		}

		this.skipLoadEvent = skipLoadEvent;
		this.imageQueue = imageQueue;
		return this;
	};

	DelayedImageLoader.prototype.start = function( options ) {

		var _options = $.extend({
			complete: function() {},
			each: function() {}
		}, options);


		var pointer = 0;
		var end = this.imageQueue.length - 1;
		var self = this;

		// create our looper
		var _run = function( i ) {

			//	console.log("Loading " + i);
			_resolveImage( self.imageQueue[i], function() {
				if (i+1 <= end) {
					_run( i+1 );
					_options.each();
				} else {
					_options.complete();
					// console.log("queue complete");
				}
			}, this.skipLoadEvent);

		};

		$(function() {
			_run( 0 );
		});

	};

	DelayedImageLoader.prototype.load = function( $img ) {
		var _complete = _loadImage( $img )[0];
		_complete();
	}


	// Deal with the switching and loading of an image.
	// return the completion function.
	var _loadImage = function( $this ) {

		var _complete = function() {};
		var $img, src;

		if ($this.attr('data-bg')) {
			src = $this.attr('data-bg');
			$img = $('<img />').attr('src', src);
			$this.css('opacity',0);
			_complete = function() {
				$this.css('background-image', 'url(' + src + ')');
				$this.css('opacity',1);
				$img.remove();
			};

		} else if ($this.attr('data-src')) {
			src = $this.attr('data-src');
			$img = $this;
			$img.attr('src', src );
			$img.css('opacity', 0);

			_complete = function() {
				$img.css('opacity', 1);
			};

		} else if ($this.attr('src')) {
			src = $this.attr('src');
			$img = $this;
		};

		return [ _complete, $img ];
	};

	var _resolveImage = function(el, callback, skipLoadEvent) {

		// whateer we got, turn it into a
		// jquery collection
		var $el = $(el);
		var deferreds = [];

		$el.each( function() {

			var $this = $(this);
			var dfd = $.Deferred();
			deferreds.push(dfd);

			// process the image, generate a 'complete' function
			// based on its info.
			var _parts = _loadImage( $this );
			var _complete = _parts[0];
			var $img = _parts[1];


			if (skipLoadEvent) {
				dfd.resolve( _complete );
			} else {
				// give 0.5 secs to resolve regardless
				// of load time.
				var timer = setTimeout(function() {
					dfd.resolve( _complete );
				}, 500);

				// use a namespaced load event, because we
				// actually might trigger the event more than
				// once and don't want to break another piece
				// of the app with multiple load events.
				$img.one("load", function() {
					$(this).trigger("image:loader");
				}).each(function() {
					// if the image had loaded already before
					// running this, we can trigger a new 'load'
					// event so that the above fires
					if (this.complete) {
				  		$(this).trigger('image:loader');
				  	}
				});

				// resolve a promise when this loads,
				// but clear the timer to resolve.
				// Hopefully this should always
				// be firing before the timer
				$img.one("image:loader", function() {
					// console.log( "Loaded:",  $(this).attr('src') );
					// resolve the promise:
					dfd.resolve( _complete );

					// run the completion function.
					// if you do not want completions to fire
					// when they're ready, but rather once this
					// current 'batch' is complete, we need to uncomment
					// the actions in the 'when' function below
					_complete();

					clearTimeout( timer );

				});
			}

		});

		// when all images have been loaded (and their
		// corresponding promises resolved) we
		// can then run their completion functions *together*
		// and then run the callback passed.
		//
		// NB: In this application the callback isn't actually dynamic
		// looking at _run above, it's always just 'run the next one'
		$.when.apply($, deferreds).then(function() {

			// IF YOU WANT COMPLETIONS TO RUN AT ONCE:
			// each promise will pass its 'complete' function,
			// so let's turn that into an array...
			// var args = [].slice.call(arguments);

			// // ...and execute each one.
			// args.forEach(function( completeFn ) {
			// 	if (typeof completeFn === "function") {
			// 		completeFn();
			// 	}
			// });

		  	// finally run the callback function, to show
		  	// that this batch has totally loaded.
		  	if (typeof callback === 'function' ) {
				callback();
			}
		});

	};





})();
