;(function() {

    var APP = window.APP || {};

	$(document).ready(function() {

		var resizeTimer;

		$(window).on('resize', function(e) {

			clearTimeout(resizeTimer);

			resizeTimer = setTimeout(function() {
				// This can sometimes fire twice in what appears to be the
				// same resizing movements - eg if people pause mid resize
				// so anything you bind to this - make sure you add some
				// code to stop a repeating firing.
				$(window).trigger('resizeEnd');

			}, 250);

		});

		APP.videos = [];


		if ($('.videoConstrainer').length) {

			$('.videoConstrainer').one('mouseover', function(e) {

				// set the video SRC,
				// which should start it playing
				var $vid = $(this).find('video');
				var src = $vid.attr('data-src');
				var $self = $(this);

				if (src) {

					var $src = $('<source  type="video/mp4">');
					$src.attr('src', src);
					$src.appendTo( $vid );

					var video = $vid[0];
					video.src = src;
					video.load();
					APP.videos.push(video);

					video.addEventListener('loadeddata', function() {

						video.play();
						// // lastly enable the show on hover.
						$self.addClass('hoverable');
						$self.on('mouseover', function() {
							// video.currentTime = 0;
							video.play();
						});

						$self.on('mouseout', function() {
							video.pause();
							// // slightly delay the reset
							// // for the opacity transition
							// setTimeout(function() {
							// 	video.currentTime = 0;
							// }, 300);
						});
					}, false);

				}

			});

		}

		if($('#navBtn').length)
		{
			var $nav = $('#nav'),
				$navBtn = $('#navBtn');

			$navBtn.on('click', function(e) {
				$navBtn.toggleClass('is-active');
				$nav.toggleClass('js-is-active');
			});

			// close nav if anything other than a link
			// was clicked.
			//
			// Use touchstart because we're not clicking
			// a button or an A tag
			$nav.on('click touchstart', function(e) {
				// if it's not a link it' sjust overlay:
				if (e.target.nodeName != 'A') {
					$navBtn.removeClass('is-active');
					$nav.removeClass('js-is-active');
				}
			})
		}

		// handle slideshow/grid interaction
		var $setContainer = $('#js-setContainer');

		if($setContainer.length)
		{

			/*----------  IMAGE LOAD DELAYING  ----------*/

			// delay our loading of images:
			// create a queue


			var queueConfig = [
				'.slideNotLazy',
				['.delayLoadThumb', 4],
				['.slideLazy', 'serial'],
			];

			var imageLoader = new DelayedImageLoader( queueConfig, true );
			imageLoader.start({
				// complete:checkHeights
			});

			/*----------  CAROUSEL  ----------*/

			// create our own carousel, it's pretty much as simple as just
			// toggling a show data attr
			var setCarousel = {

				_init: function() {
					this.cells = $('.setSlideshow__item');
				},

				_getNext: function() {
					var $active = $('.setSlideshow__item[data-show="true"]');
					var $next = $active.next();

					if ($next.length) {
						return $next;
					} else {
						return this.cells.first();
					}
				},

				_getPrev: function() {
					var $active = $('.setSlideshow__item[data-show="true"]');
					var $prev = $active.prev();

					if ($prev.length) {
						return $prev;
					} else {
						return this.cells.last();
					}
				},

				_activate: function( $el ) {
					this.cells.not( $el ).attr('data-show', false);
					$el.attr('data-show', true );
					// force a load of this one.
					imageLoader.load( $el.find('img') );
					updateCounter( $el.index() );
				},

				next: function() {
					this._activate( this._getNext() );
				},

				// zero index
				goto: function( i ) {

					this._activate( this.cells.eq( i ) )
				},

				prev: function() {
					this._activate( this._getPrev() );
				}

			};

			setCarousel._init();

			APP.setCarousel = setCarousel;

			var $gridItems = $('#js-setGrid .setGrid__item'),
				$slideTitle = $('#js-slideTitle'),
				$btnCounter = $('#js-counter'),
				$btnCounterCurrent = $btnCounter.find('.current'),
				$btnCounterTotal = $btnCounter.find('.total'),
				elem = document.querySelector('#js-flickity');

			// resize debounce
			var resizing = false;


			var $slides = $('.setSlideshow__itemInner');
			// loop through our slides. See if the image pushes
			// down past our viewport, if it does, change our width
			// to auto and fix our height, so that the image responds.
			// it's like a kind of optional object-fit kinda thing.
			var checkHeights = function() {

				requestAnimationFrame(function() {

					$slides.each(function() {
						var $s = $(this);
						var $img = $s.find('img');

						// work on ratios, thanks to knowing the image aspect ratio through
						// data attributes we know the resulting image size before lazyload kicks
						// in an repaints, as we know the container:
						var resultingImageHeight = ( $s.width() / $img.data('w') ) * $img.data('h');

						// if the result is bigger than the container, let the height define things:
						if (resultingImageHeight > $s.height()) {
							$img.width('auto').height( ( $s.height() ) );
						} else {
							$img.width('').height('');
						}
					});

				});
			};


			$(window).on('resizeEnd', checkHeights );
			checkHeights();

			var updateCounter = function( current ) {

				// get text, before current is fixed
				var title = setCarousel.cells.eq( current ).attr('data-title');
				$slideTitle.text( title );

				var current = current + 1,
					total = setCarousel.cells.length;

					// console.log( current, total );

				if(current < 10)
				{
					current = '0' + current;
				}

				if(total < 10)
				{
					total = '0' + total;
				}

				// update numbers
				$btnCounterCurrent.html(current);
				$btnCounterTotal.html(total);
			};

			updateCounter(0);

			$('.setSlideshow__items').on('click', function() {
				setCarousel.next();
			});

			// clicking on a grid thumb opens flickity to that slide
			$gridItems.on('click', function(e) {
				var index = $(this).index();
				// console.log( index );

				setCarousel.goto( index );
				// slightly delay showing the slideshow to avoid seeing
				// the slide change.

				$setContainer.addClass("js-revealing");
				$setContainer.attr('data-show', 'slideshow');
				setTimeout(function() {
					$setContainer.removeClass("js-revealing");
				}, 500);
			});

			// clicking on the show grid button shows the grid
			$btnCounter.on('click', function(e) {
				$setContainer.attr('data-show', 'grid');
			});
		}
	});

})();
